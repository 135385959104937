
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import moment from "moment";

export default defineComponent({
  name: "parent-attendance",
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const currentDate = new Date();

    // eslint-disable-next-line
    const attendance = ref<any[]>([]);

    const loading = ref(true);

    const days = ref<string[]>([]);
    // eslint-disable-next-line
    const series = ref<any[]>([]);
    const options = ref();

    const targetData = ref({
      startDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ).toString(),
      endDate: currentDate.toString(),
      subject: "All subjects",
    });

    const subjects = ref([{ _id: "0", name: "All subjects" }]);

    onMounted(() => {
      setCurrentPageTitle("dashboard.attendance");
    });

    const currentStudent = store.getters.currentStudent;
    const activeSchoolarYear = store.getters.currentYear;

    const currentClassRoom =
      currentStudent.schoolarYearsHistory[activeSchoolarYear._id];

    if (currentClassRoom)
      ApiService.post("/schedules/filter", {
        query: {
          classeRoom: currentClassRoom,
          trimester: String(store.getters.currentTrimester),
          status: { $ne: 'inactive' },
        },
        aggregation: [
          {
            $lookup: {
              from: "subjects",
              localField: "subject",
              foreignField: "_id",
              as: "subject",
            },
          },
          {
            $lookup: {
              from: "modules",
              localField: "module",
              foreignField: "_id",
              as: "module",
            },
          },
          {
            $lookup: {
              from: "modulespecifics",
              localField: "moduleSpecific",
              foreignField: "_id",
              as: "moduleSpecific",
            },
          },
          {
            $project: {
              subject: {
                $let: {
                  vars: {
                    subjectVar: {
                      $arrayElemAt: ["$subject", 0],
                    },
                  },
                  in: {
                    name: "$$subjectVar.name",
                    _id: "$$subjectVar._id",
                    status: "$$subjectVar.status",
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ["$module", 0],
                    },
                  },
                  in: {
                    name: "$$moduleVar.name",
                    _id: "$$moduleVar._id",
                    status: "$$moduleVar.status",
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ["$moduleSpecific", 0],
                    },
                  },
                  in: {
                    name: "$$moduleSpecificVar.name",
                    _id: "$$moduleSpecificVar._id",
                    status: "$$moduleSpecificVar.status",
                  },
                },
              },
              type: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          data
            .map((s) => {
              if (!s.type) s.type = "S";
              if (s.type == "MP") s.subject = s.module;
              if (s.type == "MS") s.subject = s.moduleSpecific;
              return s;
            })
            .forEach((sched) => {
              if (
                (sched.type != "S" || sched.subject.status !== "inactive") &&
                !subjects.value.find((s) => s._id == sched.subject._id)
              )
                subjects.value.push(sched.subject);
            });
        })
        .catch((e) => console.log(e));

    const labelColor = getCSSVariableValue("--bs-gray-800");
    const warningColor = getCSSVariableValue("--bs-warning");
    const lightWarning = getCSSVariableValue("--bs-light-warning");
    const dangerColor = getCSSVariableValue("--bs-danger");
    const lightDanger = getCSSVariableValue("--bs-light-danger");

    const getDaysArray = function (start, end) {
      const a: string[] = [];
      for (
        let d = new Date(start);
        d <= new Date(end);
        d.setDate(d.getDate() + 1)
      ) {
        const m = moment(d);
        a.push(`${m.date()} ${m.format("MMMM")}`);
      }
      return a;
    };

    function updateChartData() {
      const start = new Date(targetData.value.startDate);
      const end = new Date(targetData.value.endDate);

      days.value = getDaysArray(
        targetData.value.startDate,
        targetData.value.endDate
      );

      const delaySerie: number[] = [];
      const absentSerie: number[] = [];

      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        let a = 0;
        let r = 0;
        for (const att of attendance.value) {
          if (
            new Date(att.date).getDate() == d.getDate() &&
            (targetData.value.subject === "All subjects" ||
              targetData.value.subject == att.schedule.subject)
          )
            if (att.type == "Retard") r++;
            else a++;
        }
        absentSerie.push(a);
        delaySerie.push(r);
      }

      series.value = [
        {
          name: t("attendance.delays"),
          data: delaySerie,
        },
        {
          name: t("attendance.absents"),
          data: absentSerie,
        },
      ];

      options.value = {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [warningColor, dangerColor],
        },
        xaxis: {
          type: "string",
          categories: days.value,
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            stops: [0, 100],
          },
        },
        colors: [warningColor, dangerColor],
        markers: {
          colors: [warningColor, dangerColor],
          strokeColor: [lightWarning, lightDanger],
          strokeWidth: 3,
        },
      };
    }

    const delays = ref(0);
    const absents = ref(0);

    onMounted(async () => {
      await ApiService.post("attendance/filter", {
        query: {
          student: currentStudent._id,
          schoolarYear: activeSchoolarYear,
        },
      }).then(({ data }) => {
        attendance.value = data
          .filter((att) => {
            return att.schedule && att.schedule.classRoomId == currentClassRoom;
          })
          .map((att) => {
            if (!att.schedule.type) att.schedule.type = "S";
            if (att.schedule.type == "MP")
              att.schedule.subject = att.schedule.module;
            else if (att.schedule.type == "MS")
              att.schedule.subject = att.schedule.moduleSpecific;
            return att;
          });

        attendance.value.forEach((att) => {
          if (att.type == "Retard") delays.value++;
          else absents.value++;
        });

        loading.value = false;
      });
      updateChartData();
    });

    updateChartData();

    return {
      t,
      targetData,
      subjects,
      options,
      series,
      updateChartData,
      delays,
      absents,
      loading,
    };
  },
});
